// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cloud-cliff-js": () => import("./../../../src/pages/cloud-cliff.js" /* webpackChunkName: "component---src-pages-cloud-cliff-js" */),
  "component---src-pages-cybercrime-js": () => import("./../../../src/pages/cybercrime.js" /* webpackChunkName: "component---src-pages-cybercrime-js" */),
  "component---src-pages-genomic-basics-js": () => import("./../../../src/pages/genomic-basics.js" /* webpackChunkName: "component---src-pages-genomic-basics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

